import { useRef } from "react"
import { motion } from "framer-motion"

import { ReactComponent as Avocado } from "src/assets/illustrations/avocado.svg"
import { ReactComponent as Baby } from "src/assets/illustrations/baby.svg"
import { ReactComponent as Bart } from "src/assets/illustrations/bart.svg"
import { ReactComponent as Chili } from "src/assets/illustrations/chili.svg"
import { ReactComponent as Ei } from "src/assets/illustrations/ei.svg"
import { ReactComponent as Koch } from "src/assets/illustrations/koch.svg"
import { ReactComponent as Melone } from "src/assets/illustrations/melone.svg"
import { ReactComponent as Messer } from "src/assets/illustrations/messer.svg"
import { ReactComponent as Metaler } from "src/assets/illustrations/metaler.svg"
import { ReactComponent as Rauch } from "src/assets/illustrations/rauch.svg"
import { ReactComponent as Teig } from "src/assets/illustrations/teig.svg"
import { ReactComponent as Vampir } from "src/assets/illustrations/vampir.svg"

const ALL_ILLUSTRATIONS = [
  <Avocado key={0} />,
  <Baby key={1} />,
  <Bart key={2} />,
  <Chili key={3} />,
  <Ei key={4} />,
  <Koch key={5} />,
  <Melone key={6} />,
  <Messer key={7} />,
  <Metaler key={8} />,
  <Rauch key={9} />,
  <Teig key={10} />,
  <Vampir key={11} />,
]

const RandomIllustration = (): JSX.Element => {
  const randomIndexRef = useRef(Math.floor(Math.random() * ALL_ILLUSTRATIONS.length))

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5, delay: 0.2 }}>
      {ALL_ILLUSTRATIONS[randomIndexRef.current]}
    </motion.div>
  )
}

export default RandomIllustration
